import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3002/api',
    headers: {
        'Content-Type': 'application/json',
    }
});

export const registerUser = async (userData) => {
    try {
        const response = await api.post('/user/register', userData);
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(errorMessage);
    }
};

export const startSession = async (sessionData) => {
    try {
        const response = await api.post('/sessions/start', sessionData);
        return {
            ...response.data,
            sessionId: response.data.session._id
        };
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(errorMessage);
    }
};

export const getActiveSession = async (userId) => {
    try {
        const response = await api.get(`/sessions/active/${userId}`);
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(errorMessage);
    }
};

export const getCheckoutInfo = async (token) => {
    try {
        const response = await api.get(`/sessions/checkout/${token}`);
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error(errorMessage);
    }
};

export default api;