import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const cardElementOptions = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

function PaymentForm({ onSuccess, onError, clientSecret, onReady }) {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (stripe && elements) {
            onReady();
        }
    }, [stripe, elements, onReady])

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        try {
            const cardElement = elements.getElement(CardElement);

            const { setupIntent, error } = await stripe.confirmCardSetup(
                clientSecret,
                {
                    payment_method: {
                        card: cardElement,
                    },
                },
            );

            if (error) {
                throw new Error(error.message);
            }

            if (setupIntent.status === 'succeeded') {
                onSuccess(setupIntent);
            } else {
                throw new Error('Payment setup failed');
            }
        } catch (error) {
            onError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
                <CardElement options={cardElementOptions} />
            </Box>

            <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                disabled={!stripe || isLoading}
            >
                {isLoading ? <CircularProgress size={24} /> : 'Add Payment Method'}
            </Button>
        </form>
    );
}

export default PaymentForm;