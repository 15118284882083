import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Registration from './pages/Registration';
import PaymentCapture from './pages/PaymentCapture';
import SessionSetup from './pages/SessionSetup';
import SessionStatus from './pages/SessionStatus';
import Checkout from './pages/Checkout';

// Create a custom theme for QuietBox
const theme = createTheme({
  palette: {
    primary: {
      main: '#0ea5e9', // Blue
    },
    secondary: {
      main: '#64748b', // Slate gray
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/register" element={<Registration />} />
          <Route path="/payment-capture" element={<PaymentCapture />} />
          <Route path="/session/new" element={<SessionSetup />} />
          <Route path="/session/status/" element={<SessionStatus />} />
          <Route path="/checkout/:token" element={<Checkout />} />
          <Route path="/" element={<Registration />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;