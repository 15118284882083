// src/pages/SessionStatus.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Container,
    Paper,
    Typography,
    Box,
    Alert,
    Divider,
    Button
} from '@mui/material';
import { Timer, AttachMoney, Link as LinkIcon, PhoneIphone, Logout } from '@mui/icons-material';
import { getActiveSession } from '../services/api';

function SessionStatus() {
    const location = useLocation();
    const navigate = useNavigate();
    const [session, setSession] = useState(null);
    const [duration, setDuration] = useState(0);
    const [error, setError] = useState(null);

    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');

    // Fetch session status periodically
    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await getActiveSession(userId);
                setSession(response.session);
            } catch (err) {
                setError(err.message);
            }
        };

        // Initial fetch
        fetchStatus();

        // Update every 30 seconds
        const interval = setInterval(fetchStatus, 30000);

        return () => clearInterval(interval);
    }, [userId]);

    // Update duration every minute
    useEffect(() => {
        if (session?.startTime) {
            const updateDuration = () => {
                const start = new Date(session.startTime);
                const now = new Date();
                const diffMinutes = Math.floor((now - start) / (1000 * 60));
                setDuration(diffMinutes);
            };

            updateDuration();
            const interval = setInterval(updateDuration, 60000);

            return () => clearInterval(interval);
        }
    }, [session]);

    const handleCheckout = () => {
        if (session?.checkoutUrl) {
            navigate(session.checkoutUrl);
        }
    };

    const estimatedCost = (duration * 0.50).toFixed(2);

    return (
        <Container maxWidth="sm">
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        Session in Progress
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 3 }}>
                            {error}
                        </Alert>
                    )}

                    <Alert severity="info" sx={{ mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <PhoneIphone />
                            <Typography>
                                We've sent a link to your phone. You can close this window and use that link to return anytime.
                            </Typography>
                        </Box>
                    </Alert>

                    <Box sx={{ my: 4 }}>
                        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Timer color="primary" /> Duration
                        </Typography>
                        <Typography variant="h3" align="center" sx={{ my: 2 }}>
                            {duration} min
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <AttachMoney color="primary" /> Estimated Cost
                        </Typography>
                        <Typography variant="h4" align="center" sx={{ my: 2 }}>
                            ${estimatedCost}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="center">
                            $0.50 per minute
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Logout color="primary" /> Ready to Leave?
                        </Typography>
                        <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            onClick={handleCheckout}
                            startIcon={<LinkIcon />}
                            sx={{ mt: 2 }}
                        >
                            Checkout
                        </Button>
                        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                            You'll receive your lock code on the checkout page
                        </Typography>
                    </Box>

                    <Alert severity="info">
                        Need help? Contact support at team@quietbox.com
                    </Alert>
                </Paper>
            </Box>
        </Container>
    );
}

export default SessionStatus;