import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Paper, Typography, Box, TextField, Button, CircularProgress, Alert, InputAdornment } from '@mui/material';
import { parsePhoneNumberWithError as parsePhoneNumber, isValidPhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js';
import { QrCode2 } from '@mui/icons-material';
import { registerUser } from '../services/api';
import ProgressStepper from '../components/ProgressStepper';

function Registration() {
    const navigate = useNavigate();
    const location = useLocation();
    const [phoneNumber, setPhoneNumber ] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const params = new URLSearchParams(location.search);
    const locationId = params.get('locationId');
    const seamLockId = params.get('lockId');

    if (!locationId || !seamLockId) {
        return (
            <Container maxWidth="sm">
                <Box sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 4,
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        <QrCode2 sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
                        <Typography variant="h4" component="h1" gutterBottom>
                            Scan QR Code
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Please scan the QR code on your QuietBox to get started
                        </Typography>
                        {process.env.NODE_ENV === 'development' && (
                            <Alert severity="info" sx={{ mt: 3 }}>
                                Developer Note: Add locationId and lockId parameters to URL:
                                <Box component="code" sx={{ display: 'block', mt: 1, p: 1, bgcolor: 'grey.100' }}>
                                    http://localhost:3000?locationId=loc123&lockId=lock456
                                </Box>
                            </Alert>
                        )}
                    </Paper>
                </Box>
            </Container>
        );
    }

    const handlePhoneChange = (e) => {
        let value = e.target.value;
        const numericValue = value.replace(/\D/g, '');
        const formattedNumber = formatIncompletePhoneNumber(numericValue, 'US');
        setPhoneNumber(formattedNumber);
        setError(null);
    };

    const validatePhoneNumber = (phone) => {
        try {
            return isValidPhoneNumber(phone, 'US');
        } catch (error) {
            return false;
        }
    };

    const formatPhoneforApi = (phone) => {
        try {
            if (!phone.trim()) {
                return null;
            }
            const phoneNumber = parsePhoneNumber(phone, 'US');
            return phoneNumber.format('E.164');
        } catch (error) {
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!validatePhoneNumber(phoneNumber)) {
            setError('Please enter a valid phone number');
            return;
        }

        setIsLoading(true);

        try {
            const formattedPhone = formatPhoneforApi(phoneNumber);
            if (!formattedPhone) {
                throw new Error('Invalid phone number format');
            }

            const response = await registerUser({
                phoneNumber: formattedPhone,
            });

            if (!response?.setupIntent) {
                throw new Error('Invalid response from server: missing setup intent');
            }

            if (!response?.userId) {
                throw new Error('Invalid response from server: missing user ID');
            }

            navigate('/payment-capture', {
                state: {
                    setupIntent: response.setupIntent,
                    userId: response.userId,
                    locationId,
                    seamLockId
                }
            });
        } catch (error) {
            setError(error.message || 'Unable to complete registration. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        width: '100%',
                    }}
                >
                    <ProgressStepper currentStep={1} />

                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        Welcome to Quiet Box
                    </Typography>
                    <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
                        Enter your phone number to get started
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            required
                            label="Phone Number"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            error={Boolean(error)}
                            helperText={error}
                            margin="normal"
                            placeholder="(123) 456-7890"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +1
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            size="large"
                            sx={{ mt: 3 }}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Continue'}
                        </Button>
                    </form>
                </Paper>
            </Box>
        </Container>
    );
}

export default Registration;