import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Container,
    Paper,
    Typography,
    Box,
    Divider,
    Button,
    CircularProgress,
    Alert
} from '@mui/material';
import { Timer, DoorFront } from '@mui/icons-material';
import { startSession } from '../services/api';
import ProgressStepper from '../components/ProgressStepper';

function SessionSetup() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isStarting, setIsStarting] = useState(false);
    const [error, setError] = useState(null);
    const [unlockSuccess, setUnlockSuccess] = useState(false);

    const { userId, locationId, seamLockId } = location.state || {};

    useEffect(() => {
        if (!userId || !locationId || !seamLockId) {
            setError('Missing required session parameters');
            navigate('/register');
        }
    }, [userId, locationId, seamLockId, navigate])

    const handleStartSession = async () => {
        setIsStarting(true);
        setError(null);

        try {
            const response = await startSession({
                userId,
                locationId,
                seamLockId,
            });

            // Add debug logging
            console.log('Session response:', response);

            // Check for both session and sessionId
            if (!response?.session || !response?.sessionId) {
                throw new Error('Invalid session response from server');
            }

            setUnlockSuccess(true);

            setTimeout(() => {
                navigate(`/session/status?userId=${userId}`);
            }, 3000);
        } catch (err) {
            setError(
                err.message === 'Invalid session response from server'
                    ? 'Unable to start session. Please try again.'
                    : err.message || 'Failed to start session'
            );
            setIsStarting(false);
        }
    };

    if (!userId) return null;

    return (
        <Container maxWidth="sm">
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
                    <ProgressStepper currentStep={3} />

                    {!isStarting ? (
                        // Initial State - showing info and start button
                        <>
                            <Typography variant="h4" component="h1" gutterBottom align="center">
                                Ready to Start
                            </Typography>

                            {error && (
                                <Alert severity="error" sx={{ mb: 3 }}>
                                    {error}
                                </Alert>
                            )} 

                            <Box sx={{ my: 4 }}>
                                <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <DoorFront color="primary" /> How It Works
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    1. Click 'Start Session' to unlock the QuietBox
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    2. Enter and use the booth for as long as needed
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    3. Use checkout link (sent via SMS) when done
                                </Typography>
                            </Box>

                            <Divider sx={{ my: 3 }} />

                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Timer color="primary" /> Pricing
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    • $0.50 per minute
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    • $5.00 pre-authorization hold
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    You'll only be charged for the time you use
                                </Typography>
                            </Box>

                            <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                onClick={handleStartSession}
                                disabled={isStarting}
                            >
                                Start Session
                            </Button>
                        </>
                    ) : (
                        // Success state - showing unlock confirmation
                        <>
                            <Box sx={{
                                textAlign: 'center',
                                py: 4
                            }}>
                                {!unlockSuccess ? (
                                    <>
                                        <CircularProgress size={60} sx={{ mb: 3 }} />
                                        <Typography variant="h4" gutterBottom>
                                            Unlocking QuietBox...
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="h4" gutterBottom>
                                            QuietBox Unlocked!
                                        </Typography>
                                        <Alert severity="success" sx={{ mt: 3 }}>
                                            Door is now open. You can enter the booth.
                                        </Alert>
                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                                            Redirecting to session status...
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                </Paper>
            </Box>
        </Container>
    );
}

export default SessionSetup;