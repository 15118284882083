import React from 'react';
import { Stepper, Step, StepLabel, Box } from '@mui/material';

const ProgressStepper = ({ currentStep }) => {
    const steps = [
        { label: "Phone" },
        { label: "Payment" },
        { label: "Start" }
    ];

    return (
        <Box sx={{ width: '100%', mb: 4 }}>
            <Stepper
                activeStep={currentStep - 1}
                alternativeLabel
            >
                {steps.map((step) => (
                    <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default ProgressStepper;